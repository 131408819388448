// * Función para recoger información desde sessionStorage o localStorage, según si el usuario marcó o no la casilla "recordar"
/**
 * función que devuelve lo buscado en sessionStorage si existe y sino en localStorage
 * @param {*} item es el nombre del objeto buscado
 * @returns el objeto buscado, ya sea desde sessionStorage o localStorage
 */
export function getFromStorage(item) {
  if(sessionStorage.getItem(item) != undefined) {
    return sessionStorage.getItem(item);
  } else return localStorage.getItem(item);
}
