<template>
  <div class="sidenav-block">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'sidenav-block'
}
</script>
