import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

import globals from "@/globals";

// Layouts
import Layout1 from "@/layout/Layout1";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: "",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: Layout1,
      children: [
        {
          path: "login",
          name: "login",
          component: () => import(/* webpackChunkName: "login" */ "@/components/Login")
        },
        {
          path: "restablecer-password",
          name: "restablecer-password",
          component: () => import(/* webpackChunkName: "restablecer-password" */ "@/components/RestablecerPassword")
        },
        {
          path: "control-combustible",
          name: "control-combustible",
          component: () => import(/* webpackChunkName: "control-combustible" */ "@/components/controlCombustible/ControlCombustible")
        },
        {
          path: "control-usuarios",
          name: "control-usuarios",
          component: () => import(/* webpackChunkName: "control-usuarios" */ "@/components/administracion/controlUsuarios/ListaUsuarios")
        },
        {
          path: "control-contratos",
          name: "control-contratos",
          component: () => import(/* webpackChunkName: "control-usuarios" */ "@/components/administracion/controlContratos/ListaContratos")
        },
        {
          path: "detalle-usuario",
          name: "detalle-usuario",
          component: () => import(/* webpackChunkName: "detalle-usuario" */ "@/components/administracion/controlUsuarios/detalleUsuarios/DetalleUsuario")
        },
        {
          path: "control-facturacion",
          name: "control-facturacion",
          component: () => import(/* webpackChunkName: "control-facturacion" */ "@/components/administracion/ControlFacturacion")
        },
        {
          path: "cargas-trabajo-tecnicos",
          name: "cargas-trabajo-tecnicos",
          component: () => import(/* webpackChunkName: "cargas-trabajo-tecnicos" */ "@/components/controlTecnicos/CargasTrabajoTecnico")
        },
        {
          path: "form-dietas-tecnicos",
          name: "form-dietas-tecnicos",
          component: () => import(/* webpackChunkName: "form-dietas-tecnicos" */ "@/components/controlTecnicos/DietasTecnico")
        },
        {
          path: "control-tecnicos",
          name: "control-tecnicos",
          component: () => import(/* webpackChunkName: "control-tecnicos" */ "@/components/controlTecnicos/ControlTecnicos")
        },
        {
          path: "gestion-dietas-tecnicos",
          name: "gestion-dietas-tecnicos",
          component: () => import(/* webpackChunkName: "gestion-dietas-tecnicos" */ "@/components/delegaciones/GestionDietasTecnicos")
        },
        {
          path: "gestion-cargas-trabajo",
          name: "gestion-cargas-trabajo",
          component: () => import(/* webpackChunkName: "gestion-cargas-trabajo" */ "@/components/delegaciones/GestionCargasTrabajoTecnicos")
        },
        {
          path: "informe-gastos-dietas",
          name: "informe-gastos-dietas",
          component: () => import(/* webpackChunkName: "informe-gastos-dietas" */ "@/components/administracion/ResumenDietasAsesoria")
        }
      ]
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound
    }
  ]
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add("app-loading");

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

export default router;
