<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <app-layout-navbar v-if="$route.path !== '/login'" :banderas="banderas" :date="date" :dateEng="dateEng" v-on:lang="lang"/>

      <div class="layout-container">
        <app-layout-sidenav v-if="$route.path !== '/login'" :idioma="idioma" :key="componentKey" />

        <div class="layout-content">
          <div v-if="$route.path == '/login'" class="router-transitions">
            <router-view v-on:banderas="flags" />
          </div>
          <div
            v-else
            class="router-transitions container-fluid flex-grow-1 container-p-y"
          >
            <router-view
              v-bind="obtenerFecha()"
              :date="date"
              :dateSimpleSpanish="dateSimpleSpanish"
              :dateSimpleEnglish="dateSimpleEnglish"
              :day="day"
              :diaSemana="diaSemana"
              :day7minus="day7minus"
              :month="month"
              :monthMinus1="monthMinus1"
              :yearMinus1="yearMinus1"
              :year="year"
              :hour="hour"
              :idioma="idioma"
              v-on:usuarioSeleccionado="usuarioSeleccionado"
              :datosUsuario="datosUsuario"
              v-on:seleccionarFila="seleccionarFila"
              :seleccionarFila="filaSeleccionada"
            />
          </div>

          <app-layout-footer />
        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>
  </div>
</template>

<script>
import LayoutNavbar from "./LayoutNavbar";
import LayoutSidenav from "./LayoutSidenav";
import LayoutFooter from "./LayoutFooter";

export default {
  name: "app-layout-1",
  components: {
    "app-layout-navbar": LayoutNavbar,
    "app-layout-sidenav": LayoutSidenav,
    "app-layout-footer": LayoutFooter
  },
  data() {
    return {
      date: "",
      dateSimpleSpanish: "",
      dateSimpleEnglish: "",
      dateEng: "",
      month: "",
      year: "",
      day: "",
      diaSemana: "",
      day7minus: "",
      yearMinus1: "",
      monthMinus1: "",
      hour: "",
      banderas: [],
      filaSeleccionada: false,
      idioma: "",
      datosUsuario: {},
      componentKey: 0
    };
  },
  mounted() {
    this.layoutHelpers.init();
    this.layoutHelpers.update();
    this.layoutHelpers.setAutoUpdate(true);
  },
  beforeDestroy() {
    this.layoutHelpers.destroy();
  },
  methods: {
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true);
    },
    flags(datos) {
      this.banderas = datos;
    },
    lang(datos) {
      this.idioma = datos;
    },
    seleccionarFila(datos) {
      this.filaSeleccionada = datos;
    },
    usuarioSeleccionado(datos) {
      this.datosUsuario = datos
    },
    // FECHA
    // Función calculo de fecha actual
    obtenerFecha() {
      const dias = new Array(
        "Domingo",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado"
      );
      const days = new Array(
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      );
      const meses = new Array(
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      );
      const months = new Array(
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      );
      const fecha = new Date();
      this.date = `Hoy es ${dias[fecha.getDay()]} , ${fecha.getDate()} de ${
        meses[fecha.getMonth()]
      } de ${fecha.getFullYear()}`;
      this.dateSimpleSpanish = `${fecha.getDate()}/${
        meses[fecha.getMonth()]}/${fecha.getFullYear()}`;
      this.dateEng = `The date is ${days[fecha.getDay()]}, ${
        months[fecha.getMonth()]
      } ${fecha.getDate()}, ${fecha.getFullYear()}`;
      this.dateSimpleEnglish = `${
        months[fecha.getMonth()]
      }/${fecha.getDate()}/${fecha.getFullYear()}`;
      this.month = meses[fecha.getMonth()];
      this.year = fecha.getFullYear();
      this.day = fecha.getDate();
      this.diaSemana = dias[fecha.getDay()];
      this.day7minus = this.day - 7;
      this.yearMinus1 = this.year - 1;
      this.monthMinus1 = meses[fecha.getMonth() - 1];
      this.hour =
        fecha.getHours() +
        ":" +
        ((fecha.getMinutes() < 10 ? "0" : "") + fecha.getMinutes()) +
        ":" +
        ((fecha.getSeconds() < 10 ? "0" : "") + fecha.getSeconds());
    },
  }
};
</script>
