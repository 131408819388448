<template>
  <div>
    <b-navbar
      toggleable="lg"
      class="align-items-lg-center container-p-x"
      style="background:  rgba(56, 65, 119);"
    >
      <!-- Brand -->
      <b-navbar-brand>
        <img src="/images/IMASP_logos/imaspLogo.png" height="50"
      /></b-navbar-brand>

      <!-- Sidenav toggle -->
      <b-navbar-nav
        class="align-items-lg-center mr-auto mr-lg-4"
        v-if="sidenavToggle"
      >
        <a
          class="nav-item nav-link px-0 ml-2 ml-lg-0 hamburguesa"
          href="javascript:void(0)"
          @click="toggleSidenav"
        >
          <i class="ion ion-md-menu text-large align-middle" />
        </a>
      </b-navbar-nav>

      <!-- Navbar toggle -->
      <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

      <b-collapse is-nav id="app-layout-navbar">
        <b-navbar-nav class="sidenav-link align-items-lg-center barra__navegacion">
          <b-nav-item class="usuario"
            >{{ afterLoginMenu.UsuarioConectado }}:
            <strong>{{ nombre }}</strong></b-nav-item
          >
          <b-nav-item class="cerrar__sesion" @click="cerrarSesion">{{
            afterLoginMenu.CerrarSesion
          }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="align-items-lg-center ml-auto barra__navegacion">
          <b-nav-item v-if="language == 'es'">{{ date }}</b-nav-item>
          <b-nav-item v-else>{{ dateEng }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <!-- Idiomas -->
      <div class="d-flex" style="cursor: pointer; float: right;">
        <!-- Carga de las banderas en función de los idiomas disponibles, recibidos desde la bbdd -->
        <span
          v-for="flag in banderas"
          :key="flag.IDM_SEC"
          @click="bandera(flag.IDM_Siglas)"
          ><img :src="flag.IDM_Ruta" weight="20" height="20" class="mr-1"
        /></span>
      </div>
    </b-navbar>
  </div>
</template>
<style>
.barra__navegacion > li > a.nav-link {
  color: white !important;
  cursor: default;
}
.hamburguesa:hover {
  color: red !important;
  cursor: pointer;
}
.cerrar__sesion > .nav-link:hover {
  color: red !important;
  cursor: pointer;
}
.nav-link {
  cursor: default;
}
/* botón de usuario */
.navbar-light .navbar-toggler-icon {
  background-image: url("/images/IMASP_logos/user.png") !important;
  width: 2.5rem;
  height: 2.5rem;
}
.navbar-light .navbar-toggler {
  border: none;
}
</style>
<script>
// endpoint
const data = require("../../public/json/settings.json");
const API = data[0].dataUrl;
// Importación de funciones que guardan el JSON de idioma en localStorage
import {
  saveLanguages,
  getLanguageData
} from "../utilidades/setLanguageJSONLocalStorage.js";
// Importación de función que recoge la info o bien de sessionStorage o de localStorage
import { getFromStorage } from "../utilidades/storage.js";
import axios from "axios";

export default {
  name: "app-layout-navbar",
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    },
    date: {
      type: String
    },
    dateEng: {
      type: String
    }
  },
  data() {
    return {
      isLoading: true,
      banderas: [],
      language: null,
      afterLoginMenu: {},
      nombre: ""
    };
  },
  methods: {
    // función que recibe el nombre de la bandera en la que se hizo click y
    // lo guarda como nuevo lenguaje en localstorage. Después se hace post a la bbdd
    // y se guardan los datos del nuevo idioma seleccionado en localStorage.
    bandera(index) {
      localStorage.setItem("language", index);
      this.isLoading = true;
      const token = getFromStorage("token");
      this.language = getFromStorage("language");
      let datos = {
        type: "getTitulos"
      };
      let config = {
        headers: {
          Authorization: "Bearer " + token,
          "Accept-Language": this.language
        }
      };
      axios.post(API, datos, config).then(response => {
        saveLanguages(response.data.idiomaPantallas);
        this.afterLoginMenu = getLanguageData("AfterLoginMenu", this.language);
        // se emite evento para que sea escuchado el cambio de idioma
        this.$emit("lang", this.language);
        this.isLoading = false;
      });
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    getLayoutNavbarBg() {
      return this.layoutNavbarBg;
    },
    cerrarSesion() {
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({
        path: "/login"
      });
    }
  },
  created() {
    // se trae el idioma, el nombre del usuario y los textos de la navbar desde localStorage
    this.nombre = getFromStorage("name");
    this.banderas = JSON.parse(getFromStorage("flags"));
    this.language = getFromStorage("language");
    this.afterLoginMenu = getLanguageData("AfterLoginMenu", this.language);
    this.isLoading = false;
  }
};
</script>
