<template>
  <div v-if="$route.path !== '/login'">
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
      <div
        class="
          container-fluid
          d-flex
          flex-wrap
          justify-content-between
          text-center
          container-p-x
          pb-1
        "
      >
        <div class="pt-1 mt-3" style="font-size: 0.6rem;" title="Nacho Rodríguez">
          <a href="https://www.nacho-rodriguez.com" target="_blank" class="footer-link">
            Diseñado por
            <img
              src="/images/image.jpg"
              weight="30"
              height="30"
              class="ml-1 mb-2"
            />
            © 2024
          </a>
        </div>
        <div>
          <a
            href="https://imasp.net/"
            target="_blank"
            class="footer-link pt-3"
            style="font-size: 0.8rem"
            >Sobre nosotros</a
          >
          <a
            href="https://imasp.net/empresa/"
            target="_blank"
            class="footer-link pt-3 ml-3"
            style="font-size: 0.8rem"
            >Información legal</a
          >
          <a
            href="https://imasp.net/contacto/"
            target="_blank"
            class="footer-link pt-3 ml-3"
            style="font-size: 0.8rem"
            >Contacto</a
          >
          <a
            href="https://www.linkedin.com/company/ingenier%C3%ADa-y-prevenci%C3%B3n-de-riesgos-s-l/?originalSubdomain=es"
            target="_blank"
            class="footer-link pt-3 ml-3"
            style="font-size: 0.8rem"
            >Linkedin</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "app-layout-footer",

  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`;
    }
  }
};
</script>
<style lang="scss">
.nacho_info {
  display: inline-block;
  line-height: normal;
  text-align: left;
}
</style>
